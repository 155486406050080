<template>
  <div>
    <b-button class="button-pagination-brandme" @click="prevPage" :disabled="current_page === 1">
      <feather-icon icon="ChevronLeftIcon" class="icon-chevrowns"></feather-icon>
    </b-button>

    <b-button class="button-pagination-brandme ml-05" @click="nextPage" :disabled="current_page === Math.ceil(total_rows / per_page)">
      
      <feather-icon icon="ChevronRightIcon" class="icon-chevrowns"></feather-icon>
    </b-button>
  </div>
</template>

<script>
import { BButton } from "bootstrap-vue";

export default {
  name: 'PaginationBrandme',
  components: {
    BButton
  },
  props: {
    current_page: {
      type: Number,
      default: 1
    },
    total_rows: {
      type: Number,
      required: true
    },
    per_page: {
      type: Number,
      required: true
    }
  },

  created () {
  },
  methods: {
    nextPage() {
      this.$emit('update_current_page', this.current_page + 1)
    },
    prevPage() {
      this.$emit('update_current_page', this.current_page - 1)
    }
  }
}
</script>
<style scoped lang="scss">
.ml-05 {
  margin-left: 0.5em;
}
.button-pagination-brandme {
  background: white !important;
  color: black !important;
  box-shadow: none !important;
  transition: all 300ms !important;
  border-color: #ced4da !important;
  height: 42px;
  width: 42px;
  padding: 0 !important;
   
  .icon-chevrowns {
    height: 20px;
    width: 20px;
  } 
  &:hover {
    background: #E9ECEF !important;
  }
  &:disabled {
    background: #F7F8F9 !important;
    border-color: #dadada !important;
  }
}
</style>

